import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    // 登录页面
    {
        path: '/singleSignOn',
        name: 'singleSignOn',
        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/singleSignOn")
    },
    // 错误页面
    {
        path: '/errorPage',
        name: 'errorPage',
        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/errorPage")
    },
    // 无权限页面
    {
        path: '/noAccess',
        name: 'noAccess',
        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/noAccess")
    },
    {
        path: '/',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/home.vue')
        },
        children: [
            // 公告消息 详情
            {
                path: '/details',
                name: 'details',
                component: function () {
                    return import(/* webpackChunkName: "details" */ '../views/details.vue')
                },
            },
            // 历史记录 history
            {
                path: '/history',
                name: 'history',
                component: function () {
                    return import(/* webpackChunkName: "history" */ '../views/history.vue')
                },
            },
            // 未登录
            {
                path: '/error',
                name: 'error',
                component: () => import(/* webpackChunkName: "group-foo" */"@/views/error")
            },
        ]
    }
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
